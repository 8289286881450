import React, { Fragment, useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';

import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';

import { breakpoint } from '../constants/Breakpoint';
import { Heading, HeadingLarge } from '../components/.base/headings';
import { CenteredContainer, Container } from '../components/.base/containers';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { getImage } from '~/helpers';

const Hero = styled.section`
    text-align: center;
    @media screen and ${breakpoint.md} {
        padding: 120px 0 150px;
        ${RedButton} {
            margin: 54px 0 0;
        }
    }
    @media screen and ${breakpoint.maxSm} {
        padding: 75px 0 86px;
        ${RedButton} {
            margin: 52px 0 0;
        }
    }
`;
const ImageWithTextOverlay = styled.section`
    text-align: center;
    background-color: rgba(0, 0, 0, 0.65);
    position: relative;
    margin-top: 5px;
    ${Container} {
        position: relative;
        z-index: 1;
    }
    @media screen and ${breakpoint.md} {
        padding: 47px 0 50px;
        ${WhiteButton} {
            margin: 17px 0 0;
        }
    }
    @media screen and ${breakpoint.maxSm} {
        padding: 55px 0 47px;
        ${WhiteButton} {
            margin: 21px 0 0;
        }
    }
    .image {
        /* disabled for now because couldn't download the image without overlay from zeplin */
        /* mix-blend-mode: overlay; */
        position: absolute !important; // Need to overwrite the gatsby image style
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .gatsby-image-wrapper {
        width: 100%;
    }
`;

const heroData = {
    heading: '<span class="red">NO TICKETS NEEDED</span><br /><span>FOR THIS EVENT</span>',
    button_url: '/locations',
    button_text: 'LOCATIONS & MENUS',
};

const blocksData = [
    {
        heading: 'Follow us on Instagram',
        button_url: 'https://www.instagram.com/cousinsmainelobster/',
        button_text: 'Go to Instagram',
        image: 'instagram-banner-cropped@3x',
    },
    {
        heading: 'DOWNLOAD THE APP',
        button_url: '/mobile-app/',
        button_text: 'DOWNLOAD NOW',
        image: 'download-the-app-banner-cropped@3x',
    },
    {
        heading: 'VISIT OUR ONLINE SHOP',
        button_url: '/shop/',
        button_text: 'SHIP DIRECT FROM MAINE',
        image: 'online-shop-banner@3x',
    },
];

const NoTicketsNeeded = ({ data, location }) => {
    const [pageImageData, setPageImageData] = useState([]);

    useEffect(() => {
        if (data && data.pageImages) {
            setPageImageData(data.pageImages.edges);
        }
    }, [data]);

    return (
        <Fragment>
            <SEO title="No tickets needed fro this event" />
            <Criteo />
            <DeriveHeaderClasses location={location} />
            <Hero>
                <Container>
                    <CenteredContainer>
                        <HeadingLarge>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: heroData.heading,
                                }}
                            ></span>
                        </HeadingLarge>
                        <RedButton to={heroData.button_url} target="_blank" className="cta">
                            {heroData.button_text}
                        </RedButton>
                    </CenteredContainer>
                </Container>
            </Hero>
            {blocksData.map(({ heading, button_url, button_text, image }, i) => (
                <ImageWithTextOverlay key={i}>
                    <Image className="image" fluid={getImage(pageImageData, image)} alt="" />
                    <Container>
                        <CenteredContainer>
                            <Heading textTransform="uppercase" color="#fff">
                                {heading}
                            </Heading>
                            <Link to={button_url} target="_blank">
                                <WhiteButton>{button_text}</WhiteButton>
                            </Link>
                        </CenteredContainer>
                    </Container>
                </ImageWithTextOverlay>
            ))}
        </Fragment>
    );
};

export const query = graphql`
    query {
        pageImages: allFile(
            filter: {
                extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
                absolutePath: { regex: "/images/noTicketsNeeded/" }
            }
        ) {
            edges {
                node {
                    name
                    childImageSharp {
                        fluid(maxWidth: 915, quality: 100) {
                            aspectRatio
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default NoTicketsNeeded;
